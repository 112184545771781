import React, { useState } from 'react';
import '../App.css';
import '../FAQ.css';
import Navbar from '../Navbar';

const FAQPage = () => {
  const weddingFaqs = [
    {
      question: 'Gifts?',
      answer:
        'Due to the nature of a destination wedding (including you needing to pay for your trip) we are not expecting gifts, and do not have a registry.',
    },
    {
      question: 'When should I RSVP?',
      answer:
        'Our suggestion is to take an initial look at the dates and rough cost and if you are fully intending to come, mark yourself as attending so that we can get an early grasp on potential numbers. We know that it is a big and distant commitment, so the system allows you to change your status back and forth at any time in case your plans have to change. The RSVP deadline is our contract deadline to provide final numbers.',
    },
    {
      question: 'Who is attending/how big is this wedding?',
      answer:
        'There will be approximately 50 people, split about 50/50 friends and family. The wedding is kid-friendly. ',
    },
    {
      question: 'How will we know when/where to meet?',
      answer:
        'We will provide an up-to-date wedding day itinerary to your email a few days before, including details on where exactly at the resort we will be meeting in the morning. We also highly suggest you get on the WhatsApp community and keep the “Announcements” thread notifications on during the first couple resort days so that you can get any last minute updates. ',
    },
    {
      question: 'The venue travel sounds like a lot...',
      answer:
        "Everything is going to be planned out by our wedding coordinator, so we won't be left alone to usher ourselves around. We will just need to be out in front of the resort on time to catch the bus out, and we will be taken directly to the ferry, then we will be taken from the ferry directly to the catamaran. Also note the estimated timeline is a bit inflated - the travel likely won't be as long as is currently planned out.",
    },
    {
      question: "I'm uncertain about the boat ride, what should I expect?",
      answer:
        "Getting from the mainland to Cozumel we will need to take the public ferry. These are big, steady boats so expect a similar experince if you've been on any BCFerries. If you know you get seasick no matter what you might want to take something before we go, but for most people this ride should be fine. The second boat ride will be on a much smaller catamaran, about 45 minutes long. It is advertised as a calm and scenic ride close to the shoreline, so we are expecting this to be okay for most people as well. But we will have gravol on hand just in case!",
    },
    {
      question: 'How long is the wedding?',
      answer:
        'We have the venue for 7 hours. We will update the schedule closer to the date when everything is booked, but we are expecting a very full day between being at the venue and travelling to/from. If you have any concerns, definitely reach out and we will figure out what they can accommodate!',
    },
    {
      question: 'What will the ceremony be like?',
      answer:
        'There will be chairs set up on the beach for everyone to watch, and it will be pretty much exactly what you’d expect with us exchanging vows and our wedding planner officiating. Note that it will be a symbolic ceremony (we need to get married/sign in Canada).',
    },
    {
      question: 'Whats the policy on photos/phones?',
      answer:
        'The only rule in place for the wedding day will be no phones out during the ceremony, so that they don’t show up in the photos. Otherwise, we want to see your memories (from the wedding day and the rest of the vacation) so feel free to snap as many photos as you’d like and please send them either to the “Photos” chat in the WhatsApp group, or send them to us privately via WhatsApp or email. We will have photographers and videographers at the venue the entire time, so be sure to make use of them to get some good photos! As for social media, we don’t mind when/how you want to share any photos that you take.',
    },
    {
      question: "What's the plan for food?",
      answer:
        'It is going to be a long day, so we suggest you start with breakfast at the resort before we go. Once we get to the private catamaran tour (around noon) there will be canapes/finger foods served. Our full meal will be after the ceremony, around 3pm. There will be finger food again after the party, before/as we boat back (around 7 or 8pm). There will also be chances to grab something quick to eat once we get back to San Miguel and are waiting for the ferry (around 8 or 9pm), or once we get back to Playa del Carmen (around 9 or 10pm). It also looks like there are a couple places open on the resort until 2am, so there may be more time for food/party when we get back. The menu/options for the meal will be posted closer to the date.',
    },
    {
      question: 'What should I bring for the day?',
      answer:
        'We will be covered for most things everyone will need for the day, but if there’s anything you can think of that would make your day more comfortable, bring it. Sunscreen, swimwear/towel, change of shoes, change of clothes, snacks - it won’t be weird to bring a backpack to this wedding, and there will be plenty of space to put things.',
    },
    {
      question: "Help! I really don't know what to wear",
      answer: (
        <div>
          <p>Here are a few inspiration photos for the men:</p>
          <div className='dress-inspo-div'>
            <div className='dress-inspo-img'>
              <img src='/img/men1.jpg'></img>
            </div>
            <div className='dress-inspo-img'>
              <img src='/img/men2.jpg'></img>
            </div>
            <div className='dress-inspo-img'>
              <img src='/img/men3.jpg'></img>
            </div>
          </div>
          <p>And for the women:</p>
          <div className='dress-inspo-div'>
            <div className='dress-inspo-img-2'>
              <img src='/img/women1.PNG'></img>
            </div>
            <div className='dress-inspo-img-2'>
              <img src='/img/women2.PNG'></img>
            </div>
          </div>
          <p>
            Again, what's most important to us is that you wear something that
            you're comfortable in and will feel like you looked good in your
            photos.
          </p>
        </div>
      ),
    },
  ];

  const resortFaqs = [
    {
      question: 'Where is the resort/how do we get there?',
      answer: (
        <div>
          <p>
            The{' '}
            <a
              href='https://maps.app.goo.gl/nR18vK35cyesgdCu5'
              target='_blank'
              rel='noopener noreferrer'
            >
              Occidental at Xcaret Resort
            </a>{' '}
            is just outside of Playa del Carmen, about a 45-minute drive from
            the Cancun airport. You will want to book your flight into the
            Cancun International Airport (CUN). From there, you will want to
            take a pre-booked shuttle to the resort (not a taxi or any other
            ride from someone standing around at the airport) - it is around an
            hour drive. These shuttles can be booked ahead of time - we will
            provide the link to the company we are using once we have booked our
            shuttle (or, the resort may send a suggested company).
          </p>
        </div>
      ),
    },
    {
      question: 'How much should I expect to pay for the trip?',
      answer: (
        <div>
          <p>
            When we picked this resort, we were aiming for a price range of
            around $4000 for 2 adults, 7 nights, flights and stay included. You
            can go onto your favourite site that offers travel packages and
            select the furthest out date currently available (Sept/Oct 2025),
            and expect to add on a little bit more since November is a slightly
            more in-demand month. For example, this link shows flights and 7
            nights stay, September 6-13 2025, for 2 adults:
            <a
              href='https://www.expedia.ca/Playa-Del-Carmen-Hotels-Occidental-At-Xcaret-Destination-All-Inclusive.h882765.Hotel-Information?startDate=2025-09-06&endDate=2025-09-13&x_pwa=1&rfrr=HSR&pwa_ts=1730217384364&referrerUrl=aHR0cHM6Ly93d3cuZXhwZWRpYS5jYS9Ib3RlbC1TZWFyY2g%3D&useRewards=false&rm1=a2&regionId=601751&destination=Playa+del+Carmen%2C+Quintana+Roo%2C+Mexico&destType=MARKET&selected=882765&latLong=20.629571%2C-87.073173&badgeType=0&sort=RECOMMENDED&top_dp=3758.08&top_cur=CAD&userIntent=&origin=Vancouver%2C+BC%2C+Canada+%28YVR-Vancouver+Intl.%29&tripType=ROUND_TRIP&cabinClass=COACH&misId=AgiJmr3M6djVghEQgY76zciX0raBASCI_N9X%7EARIEGgIIAhoxCAESFgoDWVZSGNmsyQIqCjIwMjUtMDktMDYSFQoDQ1VOGJfdJCoKMjAyNS0wOS0xMw&packageType=fh&selectedRoomType=226558895&selectedRatePlan=250442070&mipt=AQoDQ0FEEjEKBggCEMrYHhIGCAIQrPEeGgYIAhCUuiciBggCENyuGSoGCAIQ7qkFOgUIAhDiGEABGisKBggCENT-DhIGCAIQ1P4OGgUIARCwbSIGCAIQ9LgGKgIIAjIGCAIQ9LgG&searchId=65622ea9-909f-4f5d-82ec-74a57512d531&propertyName=Occidental+at+Xcaret+Destination+-+All+Inclusive'
              target='_blank'
              rel='noopener noreferrer'
            >
              Expedia Travel Package
            </a>
          </p>
        </div>
      ),
    },
    {
      question: 'Where can I find more details on the resort?',
      answer: (
        <div>
          <p>
            This is a large resort: 765 rooms, 7 pools, 11 restaurants. There is
            a beach with a lounging/swimming area, some archaeological ruins and
            lots of animals wandering the resort. It is located beside Xcaret
            Park, but is not directly associated with the park. They do sell
            options for park passes along with your stay, and there is direct
            access from the resort to the park.
          </p>
          <p>
            These two websites seem to have a good amount of information on the
            resort:
            <a
              href='https://www.occidentalgrandxcaret.com/index.asp'
              target='_blank'
              rel='noopener noreferrer'
            >
              Vacation Store Miami,
            </a>
            <a
              href='https://www.barcelo.com/en-gb/occidental-at-xcaret-destination/?int=fastbooking-cat-afi-hot'
              target='_blank'
              rel='noopener noreferrer'
            >
              Barcelo,
            </a>
          </p>
        </div>
      ),
    },
    {
      question: 'When should I book?',
      answer:
        'Flights can typically only be booked 11 months in advance (so starting roughly mid-December for this trip). If booking separately, the resort can be booked already. Once we have booked our trip, we will notify everyone with our booking details. ',
    },
    {
      question: 'How do I book?',
      answer: (
        <div>
          <p>
            Generally, it is easier to book your entire trip using a service
            like <a href='https://www.expedia.ca/'>Expedia</a>, where you can
            book your flight and hotel all at once.
          </p>
          <p>
            Alternatively, you can book the resort through the{' '}
            <a
              href='https://www.barcelo.com/en-ca/occidental-at-xcaret-destination/?esl-k=google-ads|ng|c395561541781|me|koccidental%20xcaret|p|t|dc|a83055904736|g8012759946&id_hotel_sem=7383&gad_source=1&gclid=CjwKCAjwyfe4BhAWEiwAkIL8sIaznF6vuJO8NRY_mEG0oENRv2DV-YT60ZoN8FIJKXVxVk6j2Cal6RoCzy0QAvD_BwE'
              target='_blank'
              rel='noopener noreferrer'
            >
              official website
            </a>{' '}
            and book your flight separately via the airline of your choice.
          </p>
        </div>
      ),
    },
    {
      question: 'What’s the weather going to be like?',
      answer:
        'The averages for the area in November are highs of 30°, lows of 22°, and 8 days of rain. ',
    },
    {
      question: 'I’ve never been to a resort - what should I pack?',
      answer: (
        <div>
          <p>
            Aside from the obvious, we’ve made a list below with some things
            we’d suggest. If you are a more experienced resort-goer and have any
            other suggestions, let us know and we will add them!
          </p>
          <ul>
            <li>
              Reusable cup with straw to fill at the bars instead of using the
              resort cups - you can get one that’s bigger so you don’t need to
              go back for refills so often, and/or insulated to keep your drink
              cold longer. Also, dish soap to wash your cup in the sink if you
              bring one.
            </li>
            <li>
              A towel if you plan to go anywhere off-resort where you might need
              one - you are generally not allowed to bring the resort towels
            </li>
            <li>Extra sunscreen - it is way cheaper to buy before you leave</li>
            <li>
              Swimsuit cleaner or sink-sized packs of laundry detergent - if
              you’re not someone who has a different swimsuit for each day, you
              might want to be able to wash it
            </li>
          </ul>
        </div>
      ),
    },
    {
      question: 'What is happening for the rest of the trip?',
      answer: (
        <div>
          <p>
            We will dedicate the day before the wedding (Sunday) to a sort of
            unofficial meet-and-greet day. We will essentially plan to hang out
            in one area on the resort for the day and everyone can come and go
            as they please. We will scope out a good place the day before or
            morning of, and the location will be announced on the WhatsApp
            group.
          </p>
          <p>
            We also want to check out the parks for a day or two, so once we
            have those booked we will announce that as well and whoever is
            interested can book and join.
          </p>
          <p>
            Otherwise, we will keep the WhatsApp group up-to-date with our daily
            plans, but overall you can feel free to book your own excursions and
            make your trip however you’d like. The WhatsApp group is set up to
            allow you to create your own chats and invite others to join if you
            want to share your plans/bookings as well.
          </p>
        </div>
      ),
    },
    {
      question: 'Can I invite someone extra to the resort?',
      answer:
        "Yes, feel free to bring any non-wedding guests you'd like to the resort to make the most out of your vacation - just note that only the names (or plus one) on your invite get to join us for the wedding day.",
    },
  ];

  const [weddingOpenIndex, setWeddingOpenIndex] = useState(null);
  const [resortOpenIndex, setResortOpenIndex] = useState(null);

  const toggleWeddingAnswer = (index) => {
    setWeddingOpenIndex(weddingOpenIndex === index ? null : index);
  };

  const toggleResortAnswer = (index) => {
    setResortOpenIndex(resortOpenIndex === index ? null : index);
  };

  return (
    <div>
      <Navbar />
      <div className='main-content'>
        <div className='border-bottom blue-bg'>
          <div className='container text-center padding-top padding-bottom'>
            <div className='col-12-lg'>
              <h1>Frequently Asked Questions</h1>
              <h3>(or questions we predicted you may ask)</h3>
              <p>
                If you have any other questions, don't hesitate to ask! You may
                even find your question featured here later if it's a good one.
              </p>
            </div>
          </div>
        </div>

        <div className='faq-container'>
          <h2>The Wedding</h2>
          {weddingFaqs.map((faq, index) => (
            <div key={index} className='faq-item'>
              <div
                className='faq-question'
                onClick={() => toggleWeddingAnswer(index)}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span>{faq.question}</span>
                <span style={{ marginLeft: 'auto' }}>
                  {weddingOpenIndex === index ? '▲' : '▼'}
                </span>
              </div>
              {weddingOpenIndex === index && (
                <div className='faq-answer' style={{ marginTop: '5px' }}>
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className='faq-container'>
          <h2>The Resort/Vacation</h2>
          {resortFaqs.map((faq, index) => (
            <div key={index} className='faq-item'>
              <div
                className='faq-question'
                onClick={() => toggleResortAnswer(index)}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span>{faq.question}</span>
                <span style={{ marginLeft: 'auto' }}>
                  {resortOpenIndex === index ? '▲' : '▼'}
                </span>
              </div>
              {resortOpenIndex === index && (
                <div className='faq-answer' style={{ marginTop: '5px' }}>
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQPage;
