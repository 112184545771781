import '../App.css'; // Import your App.css file
import '../Home.css';
import Navbar from '../Navbar';
import { Link } from 'react-router-dom';

const HomePage = () => {
  return (
    <div>
      <Navbar />
      <div className='main-content'>
        <div className='container'>
          <div className='col-left'>
            <img src='/img/engagementphoto.png'></img>
          </div>
          <div className='col-right'>
            <div className='center-vertical'>
              <h1 className='mediumBrownFontColour'>We're Getting Married!</h1>
              <p>November 10, 2025 - Cozumel, Mexico</p>
              <Link to='/rsvp'>
                <button style={{ maxWidth: 200 + 'px' }} className='blueButton'>
                  RSVP
                </button>
              </Link>
              <p>Deadline: August 1, 2025</p>
            </div>
          </div>
        </div>

        <div className='brown-bg'>
          <div className='container'>
            <div className='col-5-lg'>
              <div className='center-vertical margin-right'>
                <p>We invite you to stay with us at</p>
                <h2>Occidental at Xcaret</h2>
                <p>
                  We will be staying at the Occidental at Xcaret all-inclusive
                  resort from November 8th through 15th.
                </p>
                <p>
                  <b>
                    Note: it is still to early to book flights so we haven't
                    booked our trip yet. Once we have, we will send you an email
                    with the details.
                  </b>
                </p>
                <p>
                  Please note that there is no manditory resort stay to attend
                  the wedding - feel free to adjust your travel itinerary to
                  best suit you.
                </p>
              </div>
            </div>
            <div className='col-7-lg resort-imgs img-container'>
              <img className='img img-1' src='/img/resort1.png'></img>
              <img className='img img-right img-2' src='/img/resort2.png'></img>
              <img className='img img-3' src='/img/resort3.png'></img>
            </div>
          </div>
        </div>

        <div className='blue-bg padding-top'>
          <div className='container text-center'>
            <h1 className='text-center'>The Wedding Day</h1>
            <p>
              We have chosen a unique and private wedding venue on the island of
              Cozumel to celebrate. We are keeping the exact venue a surprise,
              but all of the information you need about the location and travel
              are below.
            </p>
          </div>
        </div>

        <div className='itinerary-header'>
          <div className='container'>
            <h2>Wedding Day Itinerary</h2>
          </div>
        </div>
        <div className='container'>
          <div className='col-4-sm col-3-md'>
            <div className='itinerary-brown-bg'>
              <div className='itinerary-white-bg'>
                <div className='itinerary-flex'>
                  <div className='col-5-md itinerary-img-div'>
                    <img className='itinerary-img' src='img/bus.png'></img>
                  </div>
                  <div className='col-5-sm itinerary-txt'>
                    10am pickup from resort
                  </div>
                </div>
                <div className='itinerary-line'></div>
                <div className='itinerary-flex'>
                  <div className='col-5-md itinerary-img-div'>
                    <img className='itinerary-img' src='img/ferry.png'></img>
                  </div>
                  <div className='col-5-sm itinerary-txt'>
                    11am ferry to Cozumel
                  </div>
                </div>
                <div className='itinerary-line'></div>
                <div className='itinerary-flex'>
                  <div className='col-5-md itinerary-img-div'>
                    <img className='itinerary-img' src='img/boat.png'></img>
                  </div>
                  <div className='col-5-sm itinerary-txt'>
                    12pm private boat to venue
                  </div>
                </div>
                <div className='itinerary-line'></div>
                <div className='itinerary-flex'>
                  <div className='col-5-md itinerary-img-div'>
                    <img className='itinerary-img' src='img/beach.png'></img>
                  </div>
                  <div className='col-5-sm itinerary-txt'>
                    1pm arrive at venue
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-8-sm'>
            <h3>Part 1: Getting to the Venue</h3>
            <p>
              Transportation will be provided for everyone from the resort to
              the wedding destination (and back). Note that the times are
              currently approximate, and will be updated as we get closer to the
              date.
            </p>
            <h4>Occidental at Xcaret to Playa Del Carmen</h4>
            <p>
              A bus will arrive at the resort in the late morning to take
              everyone to the ferrry terminal. This will be approximately a 15
              minute drive.
            </p>
            <h4>Playa Del Carmen to Cozumel</h4>
            <p>
              We will have a group ferry booking for the ferry to Cozumel. We
              will be directed directly from the bus to the ferry, which will be
              a 45 minute ride to the island.
            </p>
            <h4>Cozumel to Wedding Venue</h4>
            <p>
              Once we are in Cozumel, we will be escorted to a private catamaran
              tour for the last leg of the trip. This will be a 45-minute ride
              to our venue, where canapes and drinks will be served.
            </p>
          </div>
        </div>
        <div className='container'>
          <div className='col-4-sm col-3-md'>
            <div className='itinerary-brown-bg'>
              <div className='itinerary-white-bg'>
                <div className='itinerary-flex '>
                  <div className='col-5-md itinerary-img-div'>
                    <img className='itinerary-img' src='img/beach.png'></img>
                  </div>
                  <div className='col-5-sm itinerary-txt'>
                    1pm arrive at venue
                  </div>
                </div>
                <div className='itinerary-line'></div>
                <div className='itinerary-flex '>
                  <div className='col-5-sm itinerary-img-div'>
                    <img className='itinerary-img' src='img/wedding.png'></img>
                  </div>
                  <div className='col-5-sm itinerary-txt'>2pm ceremony</div>
                </div>
                <div className='itinerary-line'></div>
                <div className='itinerary-flex '>
                  <div className='col-5-sm itinerary-img-div'>
                    <img className='itinerary-img' src='img/food.png'></img>
                  </div>
                  <div className='col-5-sm itinerary-txt'>3pm dinner</div>
                </div>
                <div className='itinerary-line'></div>
                <div className='itinerary-flex '>
                  <div className='col-5-sm itinerary-img-div'>
                    <img className='itinerary-img' src='img/dj.png'></img>
                  </div>
                  <div className='col-5-sm itinerary-txt'>5pm reception</div>
                </div>
                <div className='itinerary-line'></div>
                <div className='itinerary-flex '>
                  <div className='col-5-sm itinerary-img-div'>
                    <img className='itinerary-img' src='img/boat.png'></img>
                  </div>
                  <div className='col-5-sm itinerary-txt'>8pm leave venue</div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-8-sm'>
            <div>
              <h3>Part 2: At the Venue</h3>
              <p>
                The wedding party will need some time to get ready once we
                arrive at the venue. The bar will be open and canapes will be
                served. Take some time to hang out and enjoy the beach!
              </p>
              <h4>The Ceremony</h4>
              <p>
                The ceremony will last around 30 minutes. Nothing out of the
                ordinary here - just take a seat and watch.
              </p>
              <h4>Dinner</h4>
              <p>
                After the ceremony, there will be a sit-down meal. Menu TBD.
              </p>
              <h4>Reception</h4>
              <p>
                We have the rest of the evening to celebrate! There will be a
                DJ, space to dance, and the bar will be open.
              </p>
              <h4>Travel back</h4>
              <p>
                The way back to the resort will be the same way that we got to
                the venue. Everyone will be leaving together, on time to make it
                onto the last ferry.
              </p>
            </div>
          </div>
        </div>

        <div className='venue-bg'>
          <div className='padding-top'>
            <div className='itinerary-header'>
              <h2>The Venue</h2>
            </div>
          </div>
          <div className='container space-between'>
            <div className='col-venue text-center'>
              <div className='venue-brown-bg'>
                <div className='itinerary-white-bg padding-venue'>
                  <div className='venue-margin'>
                    <h4>Location</h4>
                    <p>
                      The ceremony and reception are both located on the beach,
                      so bring whatever you might need to keep yourself cool and
                      protected from the sun. Bring your swimsuit if you think
                      you'd want to take a dip in the ocean after the ceremony!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-venue text-center'>
              <div className='venue-brown-bg'>
                <div className='itinerary-white-bg padding-venue'>
                  <div className='venue-margin'>
                    <h4>Ammenities</h4>
                    <p>
                      The area is remote and can only be accessed via boat.
                      There is an open events room up the beach to provide
                      shade/shelter. We will also have a small guest house
                      rented nearby. This is where the wedding party will be
                      getting ready, but can be used by anyone who needs it
                      throughout the day for a rest in the A/C.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-venue text-center'>
              <div className='venue-brown-bg'>
                <div className='itinerary-white-bg padding-venue'>
                  <div className='venue-margin'>
                    <h4>Dress Code</h4>
                    <p>
                      Cocktail? Semi-Formal? Don't think about it too hard, wear
                      whatever you feel good in and will be comfortable in. It
                      will be hot (check the weather for rain), and you will get
                      sand in your shoes. Feel free to wear sandals, skip the
                      jacket, and wear short dresses. The wedding party colour
                      will be a light to mid blue.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='text-center padding-bottom'>
            <Link to='/faq'>
              <button
                className='blueButton'
                style={{ marginLeft: 10 + 'px', marginRight: 10 + 'px' }}
              >
                More Questions? Visit the FAQ Page.
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
